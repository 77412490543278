$(document).ready(function () {
    const showMore = $("#show-more");

    showMore.on('click', function (e) {
        e.preventDefault();

        var position = $($(this).attr("href")).offset().top;

        $("body, html").animate({
            scrollTop: position - 80
        }, 700, 'linear');
    });
});
